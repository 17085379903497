// Copyright (C) 2024 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { ERiskRating } from 'src/app/company-record/company-record.model';
import { getEnumKeyFromValue } from 'src/app/shared/util';

// This is the way enums should be done in Type Script
export enum Recommendation {
  MITIGATE = 'Mitigate',
  MONITOR = 'Monitor',
  NONE = 'None',
}

export enum SaqStatus {
  SUBMITTED = 'Submitted',
  NO_RESPONSE = 'No Response',
  IN_PROGRESS = 'In Progress',
  REQUESTED = 'Requested',
  ENGAGED = 'Engaged',
}

export enum MitigationLabel {
  BASIC = 'Basic',
  DEVELOPING = 'Developing',
  ADVANCED = 'Advanced',
}

export enum ImprovementOpportunity {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export function getSaqStatusDisplay(status: string) {
  switch (status) {
    case 'SUBMITTED':
      return SaqStatus.SUBMITTED;
    case 'NO_RESPONSE':
      return SaqStatus.NO_RESPONSE;
    case 'IN_PROGRESS':
      return SaqStatus.IN_PROGRESS;
    case 'REQUESTED':
      return SaqStatus.REQUESTED;
  }
}

export function getRecommendationDisplay(status: Recommendation) {
  switch (status) {
    case Recommendation.MITIGATE:
      return 'Mitigate';
    case Recommendation.MONITOR:
      return 'Monitor';
    case Recommendation.NONE:
      return 'None';
  }
}

export function getRecommendationColour(status: Recommendation) {
  switch (status) {
    case Recommendation.MITIGATE:
      return 'var(--fs-cherry)';
    case Recommendation.MONITOR:
      return 'var(--fs-blue)';
    case Recommendation.NONE:
      return 'var(--fs-aqua)';
  }
}

export function getMitigationColour(status: string) {
  switch (status) {
    case MitigationLabel.BASIC:
      return 'var(--fs-red)';
    case MitigationLabel.DEVELOPING:
      return 'var(--fs-orange)';
    case MitigationLabel.ADVANCED:
      return 'var(--fs-green)';
  }
}

// TODO: Still need this because we don't have the label for the indicators
// Should add the labels to indicators in backend so we can use "getProgressColour()"
export function getMitigationColourOnScore(value: number | undefined | null) {
  // Check for null too as the backend web-api possibly can return it explicitly. Our current types declaration may not be robust enough yet to indicate this.
  if (value === null || value === undefined) {
    return null;
  }
  if (value < 30) {
    return 'var(--fs-red)';
  }
  if (30 <= value && value <= 70) {
    return 'var(--fs-orange)';
  }
  if (70 < value) {
    return 'var(--fs-light-green)';
  }
}

export function getLabelFromMitigationScore(score: number) {
  if (score === null) {
    return null;
  }
  if (score < 30) {
    return MitigationLabel.BASIC;
  }
  if (30 <= score && score <= 70) {
    return MitigationLabel.DEVELOPING;
  }
  if (70 < score) {
    return MitigationLabel.ADVANCED;
  }
}

export function getImprovementOpportunityFromMitigationScore(score: number) {
  if (score === null) {
    return null;
  }
  if (score < 30) {
    return ImprovementOpportunity.HIGH;
  }
  if (30 <= score && score <= 70) {
    return ImprovementOpportunity.MEDIUM;
  }
  if (70 < score) {
    return ImprovementOpportunity.LOW;
  }
}

export function getRecommendation(rating: keyof typeof ERiskRating, mitigationScore: number) {
  if (rating == null || mitigationScore == null) {
    return null;
  }
  if (
    (mitigationScore <= 70 &&
      (rating === getEnumKeyFromValue(ERiskRating, ERiskRating.HIGH) ||
        rating === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE_HIGH))) ||
    (mitigationScore < 30 && rating === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE))
  ) {
    return Recommendation.MITIGATE;
  }

  if (
    (mitigationScore >= 30 && rating === getEnumKeyFromValue(ERiskRating, ERiskRating.LOW)) ||
    (mitigationScore > 70 && rating === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE_LOW)) ||
    (mitigationScore > 70 && rating === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE))
  ) {
    return Recommendation.NONE;
  }
  return Recommendation.MONITOR;
}

const recommendationSortOrder = {
  [Recommendation.NONE]: 1,
  [Recommendation.MONITOR]: 2,
  [Recommendation.MITIGATE]: 3,
};

export function getRecommendationOrder(): string[] {
  function sortKeysByDescendingValues(obj: { [key: string]: number }): string[] {
    return Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
  }
  return sortKeysByDescendingValues(recommendationSortOrder);
}

export const recommendationSort = (a: Recommendation, b: Recommendation) =>
  (a ? recommendationSortOrder[a] : 0) - (b ? recommendationSortOrder[b] : 0);
